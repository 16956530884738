import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'www.ferra.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    siteName: 'ferra',

    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),

    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.ferra.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/ferra',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    schemaOrg: {
      organizationName: 'Ferra',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 250,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s — Ferra.ru',
      defaultTitle: 'Аналитические обзоры компьютеров и комплектующих, новости и цены компьютерного рынка',
      meta: [
        { name: 'description',
          content: 'Компы, мобильники, оружие, наука',
        },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 124,
      '*': 196,
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    bannersForCapirsMonitor: [
      433128620,
      556396229,
    ],

    isUnityProject: true,

    ads: {
      siteId: '433128376',
    },

    counterIds: {
      googleAnalytics: 'UA-2289481-1',
      ga4: 'G-YL67RRGG84',
      tns: { account: 'rambler_ru', tmsec: 'ferra_total' },
      mediascope: 'rambler_cid1100108-posid2155102/',
      top100: 300511,
      yandexMetrika: 8046781,
      liveInternet: { ids: ['Lenta', 'gazeta_all'] },
      mailru: 3090298,
      rcm: resolve({
        '*': 'RCM-EADF',
        'production': 'RCM-0B25',
      }),
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
      enableCapirsMonitor: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '284B4-56A03',
        'staging': 'EA3C1-42C10',
      }),
      safariWebsitePushID: 'web.ru.ferra',
      defaultNotificationTitle: 'Ferra.ru',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/about', // чтобы работала хеш навигация
      '/review/tv/samsung-the-frame-novyy-vzglyad-na-iskusstvo.htm',
      '/review/byt/obzor-dyson-v8-proydi-test-i-poluchi-vozmozhnost-ispytat-besprovodnoy-pylesos-v-deystvii.htm',
      '/review/tv/vzglyad-v-buduschee-obzor-televizora-samsung-suhd-tv-v-novom-spetsproekte-ferra-ru.htm',
      '/review/techlife/2015.htm',
      '/exports/rss.xml',
    ],

    spammer: {
      termsUrl: 'https://help.rambler.ru/legal/1706/',
      digestKey: 'digest',
      ramblerCrmProjectKey: 'ferra',
    },

    ramblerId: {
      rname: 'ferra',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'Vkontakte', link: 'https://vk.com/ferraru' },
      { name: 'Telegram', link: 'https://t.me/ferraru' },
      { name: 'Yandex_Dzen', link: 'https://dzen.ru/ferra?favid=1670' },
    ],
  };
}
